<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.notifications')"
        :pgIcon="'bx bx-bell'"
        :refs="refs"
        :addNew="addNew"
      >
      </Breadcrumb>

      <div class="row">
        <Loading v-if="pgLoading"> </Loading>

        <div v-if="!pgLoading" class="col-8 col-sm-12">
          <div class="card">
            <div class="card-body">
              <h4
                class="card-title"
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
              >
                {{ $t("view.title") }} ( {{ title_used }} / {{ title_limit }} )
              </h4>
              <div class="tab-danger">
                <input
                  type="text"
                  class="form-control"
                  v-model="title"
                  v-on:keyup="onkeyupTitle"
                  :maxlength="title_limit"
                />
              </div>
              <br /><br />
              <h4
                class="card-title"
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
              >
                {{ $t("app.notify_msg") }} ( {{ body_used }} /
                {{ body_limit }} )
              </h4>

              <div class="tab-danger">
                <textarea
                  v-model="body"
                  rows="9"
                  v-on:keyup="onkeyupBody"
                  :maxlength="body_limit"
                  class="form-control"
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!pgLoading" class="col-4 col-sm-12">
          <div class="card" style="height: 400px">
            <div class="card-body">
              <h4
                class="card-title"
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
              >
                {{ $t("app.choose_members") }}
              </h4>
              <br />
              <p :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''">
                &nbsp;&nbsp;&nbsp;
                <input type="checkbox" @change="clickAll" /> &nbsp;&nbsp;
                {{ $t("app.all") }}
              </p>
              <div
                class="tab-danger pt-3"
                style="height: 300px; overflow-x: hidden; overflow-y: auto"
              >
                <table class="table table-striped">
                  <tbody>
                    <tr v-for="(user, idx) in users" :key="idx">
                      <td style="width: 10%">
                        <input
                          type="checkbox"
                          @change="isChecked($event, user.id)"
                          :id="user.id"
                          :value="user.id"
                          :checked="
                            selected_ids.indexOf(user.id) !== -1 ? true : ''
                          "
                        />
                      </td>
                      <td>
                        <img
                          :src="user.image ? user.image.url : ''"
                          style="width: 30px; border-radius: 50%"
                        />
                        {{ user.name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import Breadcrumb from "@/components/Breadcrumb";
import Loading from "@/components/Loading";
import Buttons from "@/components/Buttons";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default {
  name: "List",
  components: {
    Breadcrumb,
    Loading,
    Buttons,
  },
  data() {
    return {
      //
      addNew: false,

      title_used: 0,
      body_used: 0,
      title_limit: 65,
      body_limit: 240,

      title: "",
      body: "",

      all: 0,
      selected_ids: [],
      users_ids: [],

      // auth
      auth: {
        role: "",
        id_token: "",
      },

      methodType: "POST",
      pgLoading: false,
      btnCurrent: "Send",
      btnLoading: false,

      users: [],
      refs: "notifications",
    };
  },
  watch: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }

    this.fetchMembers();
    setCurrentPageTitle(this.$t("nav.notifications"), "bx bx-bell");
  },
  methods: {
    //
    fetchMembers() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: "/users",
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.users = res.data.data.rows;
          for (let i = 0; i < this.users.length; i++) {
            this.users_ids.push(res.data.data.rows[i].id);
          }
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.pgLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // all
    clickAll() {
      if (this.all == 0) {
        this.all = 1;
        for (let i = 0; i < this.users.length; i++) {
          this.selected_ids.push(this.users[i].id);
        }
      } else {
        this.all = 0;
        this.selected_ids = [];
      }
    },

    // is Checked
    isChecked($event, id) {
      if ($event.target.checked) {
        this.selected_ids.push(id);
      } else {
        this.selected_ids.splice(this.selected_ids.indexOf(id), 1);
      }
    },

    // submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: this.refs,
        method: this.methodType,
        data: {
          title: this.title,
          body: this.body,
          selected_ids: this.selected_ids,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم إرسال الرسالة بنجاح",
          });
          setTimeout(() => this.$router.go(this.$router.currentRoute), 1500);
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: "home" });
      }
    },

    //
    onkeyupTitle() {
      this.title_used = this.title.length;
    },
    //
    onkeyupBody() {
      this.body_used = this.body.length;
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },
  },
};
</script>
